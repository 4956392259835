/**
 * Wrapper class for clevertap integration
 */

import Auth from './AuthManager';
import API_ENDPOINTS from './apiConfig';
import Browser from '../utils/browser';
import { config } from 'hub-redux-auth';
import { getCookies } from "../../src/utils/cookieutil"
import AuthManager from '@services/AuthManager';
export default class ClevertapHandler {
  static initialize(CLEVER_TAP_ID) {
    if (!window.clevertap) {
      window.clevertap = {
        event: [],
        profile: [],
        region: 'in1',
        account: [],
        onUserLogin: [],
        notifications: [],
      };
      window.clevertap.account.push({
        id: CLEVER_TAP_ID,
      });
      // this create a script tag as:
      // <script type="text/javascript" async src="http://static.clevertap.com/js/a.js"></script>
      (function () {
        // create script tag
        const wzrk = document.createElement('script');
        wzrk.type = 'text/javascript';
        // script will be executed asynchronously as soon as it is available
        wzrk.async = true;
        // set src based on protocol used
        // ie: for https src="https://d2r1yp2w7bby2u.cloudfront.net/js/a.js"
        // a.js is responsible to push data on clevertap dashborad
        wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/clevertap.min.js'
        // find the first script tag
        const s = document.getElementsByTagName('script')[0];
        // insert 'wzrk' script tag in parent node of s before s
        s.parentNode.insertBefore(wzrk, s);
      }());
    }
  }

  static event(name, payload) {
    const platform = Browser.platform;
    const resellerUuid = Auth.getResellerId();
    const uuid = Auth.getUUID();
    const User = Auth.getUser() || {};
    const extraArgs = {
      Platform: config.get('product'),
      Device: Browser.getWebDevice(),
      Source: Browser.getCurrentLocation(),
      mid: User.mid,
    };
    if (Auth.isLoggedIn() && resellerUuid) {
      extraArgs.RID = resellerUuid;
    }
    if(uuid != null){
      extraArgs.UUID = uuid;
    }
    if (payload) {
      window.clevertap.event.push(name, Object.assign(payload, extraArgs));
    } else {
      window.clevertap.event.push(name, extraArgs);
    }
  }

  static sendEvent(payload) {
    try {
      const appSource = AuthManager.getAppSource();
      const utm_object = getCookies("UTM_QUERY_PARAMS")
      const resellerUuid = Auth.getResellerId();
      const uuid = Auth.getUUID();
      const User = Auth.getUser() || {};
      const extraArgs = {
        Platform: appSource,
        Device: Browser.getWebDevice(),
        Source: Browser.getCurrentLocation(),
        mid: User.mid,
        "CTA Page" : "Onboarding",
        "CTA Action" : "Update",
      };
  
      if (Auth.isLoggedIn() && resellerUuid) {
        extraArgs.RID = resellerUuid;
      }
      if(uuid != null){
        extraArgs.UUID = uuid;
      }
      const extraparms = {...extraArgs , ...utm_object}
      if (payload) {
        console.log(payload);
        window.clevertap.event.push("Dashboard CTA Clicked",Object.assign(payload, extraparms));
      } 
    } catch (error) {
      console.log(error);
      
    }
  }
// event name, dynami params in new funtion 
// new utility for API 

  static profile(payload) {
    window.clevertap.profile.push(payload);
  }
  // Maintaining Multiple User Profiles on the Same Device

  static onUserLogin(payload) {
    window.clevertap.onUserLogin.push(payload);
  }
}
